import React from 'react';
import { SDKRefItems } from '../utils/SDKRefs';
import ReferencePage from '../components/common/ReferencePage';
import { readFileSync } from 'fs';
import { replaceRoutes } from '../utils/mapRoutes';

export function getServerData() {
  let type = 'tsx';

  const sideNav = readFileSync(`./src/utils/sideNavItems.json`, 'utf8');
  const sideNavItem = JSON.parse(sideNav);

  const route = readFileSync(`./src/docs-config/src/constants/routes.json`, 'utf8');
  const routes = JSON.parse(route);

  const sideNavItems = replaceRoutes(sideNavItem, routes);
  
  return {
    props: {
    clientSideNavList: sideNavItems || [],
    type: type,
    }
  };
}

const SDKRefPage: React.FC = () => {
  return (
    <ReferencePage
      title="SDK references"
      description="Integrate with Skyflow's client-side and server-side SDKs."
      header1={SDKRefItems[0].title}
      header2={SDKRefItems[1].title}
      items={SDKRefItems[0].child}
      items1={SDKRefItems[1].child}
    ></ReferencePage>
  );
};

export default SDKRefPage;
